aside {
  padding: 20px 24px;
  background: var(--gray0);
  border-radius: 8px;
}

.form-control[readOnly] {
  color: #6e84a3 !important;
  cursor: default;
  background-color: _palette(bg-readonly) !important;
}

.form-check-input[type="checkbox"] {
  -webkit-print-color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #e3ebf6;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: transparent;
  color-adjust: exact;
  height: 16px;
  margin-top: 0.25em;
  vertical-align: top;
  width: 16px;
  cursor: pointer;
}

.form-check-input[type="checkbox"] {
  background-color: #ffffff;
  border: 0.9375px solid #111317;
  border-radius: 3.75px;
}

.form-check-input:checked {
  background-color: #111317;
}

.form-check:not(.form-switch):not(.form-check-circle) .form-check-input:checked {
  background-size: 80% 80%;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-control:focus {
  border-color: _palette(blue) !important;
}

.input-group-flush > .form-control {
  border: 0 !important;
}

.custom-select:focus {
  border-color: _palette(blue) !important;
}

.is-invalid > .custom-select {
  border-color: _palette(red) !important;
}

.accordion-height {
  height: 100% !important;
}

.accordion-show:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
  transform: rotate(-180deg);
}

.accordion-collapse:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
}

.recommendation-show:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
  transform: rotate(-180deg);
}
.recommendation-collapse:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
}
.recommendation-copy-button {
  cursor: pointer;
}
.recommendation-instruction-list {
  list-style-position: outside;
  color: _palette(copy-state);
}
.issue-detail-header {
  border-bottom: 1px solid #eaeef3 !important;
  height: 95px;
}
.issue-details-container {
  background: #f7f9fb;
  padding: 10px;
  border-radius: 6px;
  margin-top: 15px;
}
.email-recommendation-subtitle {
  margin-bottom: 0.75rem !important;
}
.copy-instructions-container {
  background: #ffffff;
  color: var(--gray90);
  padding: 10px 5px;
  border-radius: 6px;
}
.search {
  background-color: var(--gray10) !important;
  border: 0px;
}

.search-filters {
  color: _palette(black) !important;
}

.search-filters::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: _palette(black);
  opacity: 1; /* Firefox */
}

.search-filters:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: _palette(black);
}

.search-filters::-ms-input-placeholder {
  /* Microsoft Edge */
  color: _palette(black);
}

.card-shadow {
  box-shadow:
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.35),
    0px 3px 12px -3px rgba(0, 0, 0, 0.12);
}

.dropdown-menu {
  box-shadow: $card-box-shadow;
}

.dropdown-menu-card .card-body {
  overflow-y: visible !important;
}

.custom-dropdown-button[aria-expanded="true"] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.custom-dropdown-menu {
  min-width: 100% !important;
  font-size: 0.8125rem !important;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.custom-dropdown-option {
  font-weight: normal;
  display: block;
  line-height: 1.75 !important;
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
  padding-left: 0.5rem !important;
}

.dropdown-menu-options:hover {
  background: var(--lm-gray-0, #f2f4f7) !important;
  color: var(--black, #000) !important;
}

.context-menu {
  position: fixed;
  background-color: white;
  z-index: 999;
  padding: 0px;
  box-shadow:
    0px 2px 6px rgba(39, 39, 39, 0.12),
    0px 5px 10px rgba(39, 39, 39, 0.1),
    0px 8px 16px rgba(39, 39, 39, 0.08);
  border-radius: 8px;
}

.context-menu-item {
  cursor: pointer;
  padding: 12px;
  margin: 0px;

  &:hover {
    background-color: _palette(border-light);
  }
}

.tag {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding: 2px 5px;
  text-transform: none;
}

.tag-blue {
  background: #edf2ff;
  color: #2252c4;
}

.tag-uppercase {
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.table-link {
  height: 30px;
}

.table-link:hover {
  cursor: pointer;
}

.forced-feedback {
  display: block !important;
}

.ellipses-dropdown-item {
  font-size: 0.9375rem !important;
}

.bg-light-blue {
  background-color: #f9fbfd;
}
.linked-account-table-row-border {
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}

// override size of avatar-xs to be smaller
.avatar-xs {
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.54167rem;
}

/* Merge code block*/
.code-block {
  max-height: 500px;
  // border-radius: 10px;
  padding: 16px 20px;
}

.code-block-container {
  border-radius: 10px;
  background: rgb(1, 22, 39);
  color: #60819c;
}

.code-block-header {
  margin: 12px;
}

.code-block-language {
  margin: 12px;
  cursor: pointer;
}

.code-block-language-selected {
  color: #127cd2;
}

.clickable:hover {
  cursor: pointer;
}

.clickable-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* Dashboard css */
.avatar-title {
  background-color: #ffffff;
  color: #7d899a;
  box-shadow: 0px 1px 4px #e6e6e6;
  border: none;
}

.avatar-img {
  border: none;
}

.avatar-img .rounded {
  border-radius: 16px !important;
}

.no-flex-wrap {
  flex-wrap: nowrap;
}

.card-body-bottom {
  padding-bottom: 12px !important;
}

.linked-accounts-separator {
  margin-left: -24px;
  margin-right: -24px;
}

.linked-accounts-small-separator {
  margin-top: 10px;
}

.linked-accounts-description {
  margin-top: 0.5625rem;
  width: 160px;
  line-height: 1.5;
  height: 40px;
  margin-left: 5px;
}

.deprecated-rounded {
  border-radius: 16px !important;
}

.rounded-table-corner {
  border-radius: 7px;
}

.dashboard-number-header {
  font-size: 40px;
  line-height: 1.5;
  color: _palette(black);
  font-weight: 600 !important;
}

/* Merge tooltip css */
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #111317 !important;
}

.custom-header-border {
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}

.custom-header-body {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

/* Integrations dashboard */
.integration-dashboard-table-nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.integration-dashboard-table-nav-link:focus,
.integration-dashboard-table-nav-link:hover {
  text-decoration: none;
}
.integration-dashboard-table-nav-link.disabled {
  color: var(--gray50);
  pointer-events: none;
  cursor: default;
}

/* Webhooks */
.webhook-common-model-delete:hover {
  background-color: rgba(230, 55, 87, 0.03) !important;
  color: #e63757 !important;
}

.webhook-common-model-delete:hover span {
  color: #e63757 !important;
}

.blue-modal-card {
  height: 400px;
  overflow: auto;
}

.modal-shadow {
  box-shadow: 0px 4px 20px rgba(31, 45, 61, 0.35);
}

/* Rechart css */
.recharts-cartesian-axis-tick {
  font-size: 0.8125rem;
  font-family: var(--font-family-sans-serif);
  color: var(--gray50);
}

.recharts-tooltip-popover {
  padding: 0.8rem 0.95rem;
  z-index: 1060;
  display: block;
  max-width: 10rem;
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(18, 38, 63, 0.2);
  border-radius: 0.5rem;
}

.nav-item.active {
  background: linear-gradient(0deg, #3d63eb 5%, white 0%);
}

.live-waiting--container {
  width: 16px;
  height: 16px;
}

%live-waiting {
  &.bg-red {
    background: _palette(red);
  }

  &.bg-green {
    background: _palette(green);
  }
}

.live-waiting--outer-circle {
  @extend %live-waiting;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  animation-name: ping-dissipate;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
}

.live-waiting--inner-circle {
  @extend %live-waiting;
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
}

/* Rotating */

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  transform-origin: center;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@keyframes ping-dissipate {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(2.35);
    opacity: 0;
  }
}

/* Error boundary */

.error-boundary {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: _palette(gray);
  text-align: center;
}

.error-boundary-text {
  color: _palette(gray) !important;
}
