@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url("../../fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url("../../fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url("../../fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url("../../fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Regular.ttf") format("ttf");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Medium.ttf") format("ttf");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

/* Roboto doesnt have a direct 600 font-weight option */
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Medium.ttf") format("ttf");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Bold.ttf") format("ttf");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}
