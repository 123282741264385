.integrations-list {
  margin-bottom: auto;
  .fe-chevron-down {
    width: 20px;
    height: 20px;
  }
  &-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
    }
  }
}

.grayout {
  opacity: 0.3; /* Real browsers */
  filter: alpha(opacity = 30); /* MSIE */
}

.active-sorted-row {
  color: var(--gray50) !important;
  cursor: row-resize !important;
}

.sortable-integration-handle {
  color: var(--gray50) !important;
  cursor: row-resize;
  width: max-content;
  height: max-content;
}

.sortable-integration-handle:hover {
  opacity: 0.7;
}

.sortable-integration-handle:active {
  cursor: row-resize;
}

.multi-switch-button-group {
  background-color: #eff1f4;
  border-radius: 5px;
}

.multi-switch-button {
  background-color: #eff1f4;
  border: solid 0px #eff1f4 !important;
  padding: 6px 10px !important;
  outline: none;
  box-shadow: none !important;
  border-radius: 5px !important;
  color: #7283a0;

  &:hover,
  &:focus {
    background-color: #eff1f4 !important;
    box-shadow: none !important;
    color: #5d6980;
  }
}

.multi-switch-button-rounded {
  background-color: #eff1f4;
  border: solid 0px #eff1f4 !important;
  padding: 3px 12px !important;
  outline: none;
  box-shadow: none !important;
  border-radius: 14px !important;
  color: #697387;

  &:hover,
  &:focus {
    background-color: #eff1f4 !important;
    box-shadow: none !important;
    color: #5d6980;
  }
}

.multi-switch-button-selected {
  border: solid 0px #eff1f4 !important;
  padding: 6px 10px;
  border-radius: 5px !important;
  outline: none;
  box-shadow: none !important;
}

.multi-switch-button-selected-rounded {
  border: solid 0px #eff1f4 !important;
  padding: 3px 12px;
  border-radius: 14px !important;
  outline: none;
  box-shadow: none !important;
}

.configuration-fieldmappings-editfieldmapping-header-text {
  color: #8492a6;
  font-size: 12px;
  padding: 4px 0;
}

.configuration-fieldmappings-editfieldmapping-header-text-v2 {
  color: var(--slate90);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 0;
}

.configuration-fieldmappings-newfieldmapping-button-text-v2 {
  margin-left: 4px;
  color: var(--gray70);
}

.configuration-fieldmappings-newfieldmapping-button-text {
  margin-left: 4px;
  color: #8492a6;
}

.configuration-fieldmappings-button-text {
  font-weight: 600;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
}

.configuration-fieldmappings-key-text-v2 {
  font-family: var(--font-family-monospace);
  color: #080808;
  /* App + Docs/Regular/Medium Monospace */
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.configuration-fieldmappings-key-text {
  font-size: 12px;
  font-family: var(--font-family-monospace);
}

.configuration-fieldmappings-description-text-v2 {
  font-size: 12px;
  color: #939aa5;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.configuration-fieldmappings-description-text {
  font-weight: 600;
  font-size: 12px;
  color: #aaacb4;
}

.star-toggle-container {
  padding: 0px !important;
}

.configuration-customfields-cancel-button-v2 {
  border: 0.5px solid var(--white, #fff) !important;
}

.configuration-fieldmappings-new-target-field {
  box-shadow:
    0px 1.5px 4px -1px rgba(0, 0, 0, 0.16),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2) !important;
  border: none !important;
}
