button,
.btn {
  font-weight: 600;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}

.btn-primary {
  background: _palette(blue);
  border: 1px solid _palette(blue);

  &.btn-glow {
    box-shadow:
      0px 0px 2px rgba(0, 57, 229, 0.13),
      0px 2px 4px rgba(0, 57, 229, 0.14),
      0px 4px 8px rgba(0, 57, 229, 0.15),
      0px 8px 16px rgba(0, 57, 229, 0.16),
      0px 16px 32px rgba(0, 57, 229, 0.17),
      0px 32px 64px rgba(0, 57, 229, 0.18);

    &:hover {
      box-shadow:
        0px 0px 2px rgba(0, 57, 229, 0.18),
        0px 2px 4px rgba(0, 57, 229, 0.19),
        0px 4px 8px rgba(0, 57, 229, 0.2),
        0px 8px 16px rgba(0, 57, 229, 0.21),
        0px 16px 32px rgba(0, 57, 229, 0.22),
        0px 32px 64px rgba(0, 57, 229, 0.23);
    }
  }
}

.btn-primary:hover {
  color: #fff;
  background-color: _palette(blue-dark);
  border-color: _palette(blue-dark);
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: _palette(blue-dark);
  border-color: _palette(blue-dark);
  box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: _palette(blue);
  border-color: _palette(blue);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: _palette(blue-dark);
  border-color: _palette(blue-dark);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(76, 143, 233, 0.5);
}

.btn-white {
  color: _palette(black);
}

.btn-white:hover {
  color: _palette(black);
}

.btn-white.focus,
.btn-white:focus {
  color: _palette(black);
  box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
  color: _palette(black-light);
  background-color: #fff;
  border-color: var(--gray10);
}

.btn-white.subtle-shadow {
  border-width: 0.5px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.16);
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  color: _palette(black-light);
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
}

.btn-outline-primary {
  color: _palette(blue);
  border-color: _palette(blue);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: _palette(blue);
  border-color: _palette(blue);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.15rem rgba(44, 123, 229, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: _palette(blue);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: _palette(blue);
  border-color: _palette(blue);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(44, 123, 229, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: _palette(red);
  border-color: _palette(red);
}
.btn-danger:hover {
  color: #fff;
  background-color: var(--red60);
  border-color: var(--red70);
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: var(--red60);
  border-color: var(--red70);
  box-shadow: 0 0 0 0.15rem rgba(234, 85, 112, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: _palette(red);
  border-color: _palette(red);
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: var(--red70);
  border-color: var(--red80);
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgba(234, 85, 112, 0.5);
}

.btn-outline-danger {
  color: _palette(red);
  background-color: var(--red0);
  border-color: var(--red0);
}

.btn-light {
  color: var(--gray70);
  background-color: var(--gray0);
}

.btn-link-demo-ctn {
  border-radius: 6px;
  background: _palette(white);
}

.btn-selected {
  border-color: _palette(blue) !important;
  box-shadow:
    0 0 0 1.5px rgba(0, 63, 255, 1),
    0 0.5rem 2rem 0.25rem rgba(18, 38, 63, 0.08);

  &:focus {
    box-shadow:
      0 0 0 1.5px rgba(0, 63, 255, 1),
      0 0.5rem 2rem 0.25rem rgba(18, 38, 63, 0.08),
      0 0 0 calc(1.5px + 0.15rem) rgba(223, 226, 230, 0.5);
  }
}

.btn-filter-selected {
  color: _palette(blue);
  border-color: _palette(blue);
}

.btn-white.dropdown-toggle.shadow {
  display: flex;
  align-items: center;
  box-shadow: $card-box-shadow !important;
  border: 0px;
}

.btn-white.dropdown-toggle.shadow::after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform 0.2s;
  color: _palette(gray);
}
