@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* DO NOT REMOVE this enables the blue borders to work */
@layer base {
  * {
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/); /* Apply inset ring to all elements */
  }
}
