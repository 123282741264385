.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 0px 5px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 0px 5px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #e63757;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.billing-plan {
  &-type {
    display: flex;
    align-items: center;
  }
  &-name {
    float: left;
    margin: 0px;
    margin-right: 6px;
  }
}
.billing-plan-subheader {
  font-size: 13;
  color: _palette(gray-between);
}

ul.leaders {
  max-width: 400em;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  color: #c9d0da;
  content: "...................." "...................." "...................."
    "...................." "...................." "...................." "...................."
    "...................." "....................";
}
ul.leaders span:first-child {
  padding-right: 0.33em;
  background: #f9fafb;
}
ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: #f9fafb;
}
