.SignUp,
.Login,
.ForgotPassword,
.AuthForm,
.ResetPassword {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background: url("../../../assets/img/background_hero.png");
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100%;
  }

  a {
    color: _palette(black);
  }

  &-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 24px 40px;
    height: 88px;
  }

  &-form {
    min-height: 100vh;
    margin-left: 16px;
    margin-right: 16px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;

    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 52px;
    }
  }

  .form-group {
    max-width: 440px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
  }

  label:not(.form-check-label) {
    font-weight: 600;
  }

  input.form-control {
    background: #ffffff;
    border-radius: 6px;
    height: 48px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: 0;
    box-shadow:
      0px 1.5px 4px -1px rgba(0, 0, 0, 0.16),
      0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
    &::placeholder {
      color: #70767f;
    }
  }

  .btn-primary {
    background: _palette(black);
    border-radius: 6px;
    height: 40px;
    & {
      border: 0;
    }
    font-weight: 600;
    margin-top: 24px;
  }

  .btn-sign-up-instead,
  .btn-sign-in-instead {
    background: _palette(black);
    color: #ffffff;
    border: 1px solid #dce2e4;
    height: 40px;
    border-radius: 20px;
    padding: 0 1rem;
    font-weight: 600;
    white-space: nowrap;
    transition: all 0.15s ease-out;
    margin-left: 10px;
  }

  .btn-request-a-demo {
    background: #ffffff;
    border: 1px solid #dce2e4;
    height: 40px;
    border-radius: 20px;
    padding: 0 1rem;
    font-weight: 600;
    white-space: nowrap;
    transition: all 0.15s ease-out;
    line-height: 2.5;

    &:hover {
      background: #f3f4f6;
      cursor: pointer;
    }
  }

  .btn-sign-in-with-button {
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000;

    background: #ffffff;

    box-sizing: border-box;
    box-shadow:
      0px 3px 12px -3px rgba(0, 0, 0, 0.12),
      0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
    border-radius: 6px;
  }
}

.SignUp {
  &:before {
    background: url("../../../assets/img/background_hero.png");
    content: "";
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100%;
  }

  &-form {
    margin-left: 16px;
    margin-right: 16px;

    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-group {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .selling-points {
    @media (min-width: 768px) {
      padding-left: 40px !important;
    }
    @media (min-width: 992px) {
      padding-left: 96px !important;
    }

    h3 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  .selling-point {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin-left: 4px;
    margin-bottom: 24px;
    display: flex;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 28px;
      margin-left: 0;
    }

    &-check {
      margin: 2px 16px 0 0;
      min-width: 20px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: rgba(0, 63, 255, 0.09);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;
      color: _palette(blue);
      font-size: 16px;
    }
  }
}
