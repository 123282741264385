.badge {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 4px;
}

.badge-primary {
  color: #fff;
  background-color: _palette(blue);
}

.badge-soft-primary {
  background: #edf2ff;
  color: _palette(blue);
}

.badge-soft-danger {
  color: _palette(red);
  background-color: _palette(red-bg);
}

.badge-soft-danger[href]:focus,
.badge-soft-danger[href]:hover {
  color: _palette(red);
  text-decoration: none;
  background-color: _palette(red-bg-hover);
}

.badge-soft-warning {
  color: _palette(yellow);
  background-color: _palette(yellow-bg);
}

.http-method-badge {
  padding: 2px 5px;
  border-radius: 4px;

  b {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
  }
}

.badge-green {
  color: var(--teal50);
  background-color: var(--teal0);
}

.badge-purple {
  color: var(--purple50);
  background-color: var(--purple0);
}

.badge-light {
  color: var(--gray70);
  background-color: var(--gray0);
}
