.modal.fit-content .modal-dialog {
  width: fit-content;
}

.date-picker-font {
  font-family: var(--serif-font-family) !important;
}

.date-picker-body {
  overflow-y: visible;
}
.date-range-dash {
  font-weight: 400;
  font-style: normal;
  font-style: 15;
  line-height: 24;
  color: _palette(black);
}
.date-picker {
  display: flex;
  color: _palette(black);
  font-size: 15px;
}

.date-picker-day {
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
  color: _palette(black);
}
.date-picker-dash {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: _palette(black);
}

.day-disabled {
  color: var(--gray20);
}

.calender-set-button {
  width: 100%;
  width: stretch;
}

.react-datepicker__header {
  text-align: center;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__day--selected {
  background-color: _palette(blue);
}
.react-datepicker__day--keyboard-selected {
  background-color: _palette(blue);
}
.react-datepicker__day--selected:hover {
  background-color: _palette(blue);
}
.react-datepicker__day--in-selecting-range {
  background-color: _palette(blue);
}
.react-datepicker__day--in-selecting-range:hover {
  background-color: _palette(blue);
}
.react-datepicker__current-month {
  margin-top: 0;
  color: _palette(black);
  font-weight: 600;
  font-size: 13px;
}
