.log-side-panel {
  background: white;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1050;
  overflow-y: auto;
  box-shadow: -1px 0px 0px var(--gray10);
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.log-entry-side-panel-banner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  position: relative;
  color: #8492a6;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: inherit;
}

.log-entry-section {
  &-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  &-key-text {
    font-size: 12px;
    font-weight: 600;
    color: #8492a6;
  }
  &-value-text {
    font-size: 15px;
    font-weight: 400;
  }
}
.log-data-text {
  color: _palette(black);
  font-weight: 400;
  font-size: 15;
}
