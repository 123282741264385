.issue-name {
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
}

.issue-subdetail {
  display: inline-flex;
  align-items: center;
}
