body {
  color: _palette(black);
  background: _palette(bg);
  margin: 0;
}

/*  DO NOT REMOVE the below styles, these were done by mendable previously but after removing all borders around components get messed up */
* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

* ::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

* ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
