.search-input {
  border-bottom: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: rgb(187, 187, 187);
  border-color: _palette(blue);
  background-color: _palette(blue);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
