.opaque-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #12263f;
}
.opaque-backdrop.fade {
  opacity: 0;
  pointer-events: none;
}
.opaque-backdrop.show {
  opacity: 0.5;
}

.log-side-panel {
  background: white;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1050;
  overflow-y: auto;
  box-shadow: -1px 0px 0px var(--gray10);
}
