.individual-issue {
  &-resolved-button {
    background-color: var(--gray10);
  }
  &-mute-button {
    font-weight: 400;
    color: _palette(black);
    margin-right: 10px;
    display: flex;
    align-items: center;
    &-align {
      display: flex;
      align-items: center;
    }
    &-no-border {
      border-left-width: 0px;
      margin-right: 10px;
    }
    &-muted {
      display: flex;
      align-items: center;
      background-color: var(--gray10);
    }
    &-select {
      color: #6e84a3;
      font-size: 0.9375rem;
      background-color: transparent;
    }
    &-custom-count-select {
      width: 100%;
      width: stretch;
    }
  }
}
