.demo-img-frame {
  display: flex;
  justify-content: center;
  background: _palette(bg-img);
  border: 1px solid _palette(border-light);
  border-radius: 0.5rem;
  box-shadow: none;
  position: relative;
  overflow: hidden;
}

.link-diagram {
  width: 100%;
  max-width: 780px;
}

.onboarding-step {
  li {
    margin-bottom: 0.375rem;
  }
}
