.alert-primary {
  color: #fff;
  background-color: _palette(blue);
}

.alert-dark {
  color: #fff;
  background-color: _palette(black);
}

.alert-light {
  color: _palette(gray);
  background: _palette(bg-img);
  border: 1px solid _palette(border) !important;
  border-radius: 0.5rem !important;
}
